import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import axios from "axios";
import {
  ApproveOutcomingRequests,
  GetOutgoingHGOFilteredRequests,
  GetOutgoingHGORequests,
  GetOutgoingMonazamFilteredRequests,
  GetOutgoingMonazamRequests,
  GetOutgoingRequests,
  RejectOutcomingRequests,
} from "../../../../../Actions/RequestActions";
import { formatAmount, SuccessToast } from "../../../../../Util";
import { Spinner } from "react-bootstrap";

const Table = ({ reload, filters, setTotalPages, page, setPage }) => {
  const { rollId } = useRollId(); // Use the context to get rollId
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = () => {
    SuccessToast("Request approved successfully.");
    fetchOutgoingRequests();
  };

  const handleReject = () => {
    SuccessToast("Request rejected successfully.");
    fetchOutgoingRequests();
  };

  const approveRequest = (request) => {
    ApproveOutcomingRequests(request.id, setError, handleApprove);
  };

  const rejectRequest = (request) => {
    RejectOutcomingRequests(request.id, setError, handleReject);
  };

  // Function to fetch data from the outgoing request API
  const fetchFilteredOutgoingRequests = async () => {
    if (!!rollId) {
      if (rollId === "1") {
        GetOutgoingMonazamFilteredRequests(
          filters,
          setData,
          1,
          setLoading,
          setError,
          setTotalPages
        );
      } else {
        GetOutgoingHGOFilteredRequests(
          filters,
          setData,
          1,
          setTotalPages,
          setLoading,
          setError
        );
      }
      setPage(1);
    }
  };

  // Function to fetch data from the outgoing request API
  const fetchOutgoingRequests = async () => {
    if (!!rollId) {
      setLoading(true);
      if (rollId === "1") {
        GetOutgoingMonazamRequests(
          setData,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      } else {
        GetOutgoingHGORequests(
          setData,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      }
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    console.log("Filters: ", filters);
    if (!filters.date && !filters.categoryType && !filters.currency) {
      fetchOutgoingRequests();
    } else {
      fetchFilteredOutgoingRequests();
    }
  }, [reload, rollId, filters, page]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {rollId === "1" && (
                <th className="tableheader-txt py-3">HGO Name</th>
              )}
              <th className="tableheader-txt py-3">Date</th>
              <th className="tableheader-txt py-3">Narration</th>
              <th className="tableheader-txt py-3">Type</th>
              <th className="tableheader-txt py-3">Currency</th>
              <th className="tableheader-txt py-3">Amount</th>
              {/* Combine Actions/Status into one column */}
              <th className="tableheader-txt py-3">
                {rollId === "1" ? "Actions" : "Status"}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((request, index) => (
              <tr key={index}>
                {rollId === "1" && (
                  <td className="tabledata-txt py-3">
                    {request.hgoUser?.name}
                  </td>
                )}
                <td className="tabledata-txt py-3">
                  {new Date(request.date).toISOString().split("T")[0]}
                </td>
                <td className="tabledata-txt py-3">{request.narration}</td>
                <td className="tabledata-txt py-3">
                  {request.categoryType === "MonazamTransfer"
                    ? "Monazam to Opap Transfer"
                    : "Opap to Ehajj Transfer"}
                </td>
                <td className="tabledata-txt py-3">{request.currency}</td>
                <td className="tabledata-txt py-3">
                  {formatAmount(request.amount)}
                </td>
                {rollId === "1" && request.status === "pending" ? (
                  <td className="tabledata-txt pt-3">
                    <button
                      className="btn btn-sm approved-btn me-2"
                      onClick={() => {
                        approveRequest(request);
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-sm rejectbtn"
                      onClick={() => {
                        rejectRequest(request);
                      }}
                    >
                      Reject
                    </button>
                  </td>
                ) : (
                  <td className="tabledata-txt">
                    {request.status === "rejected" ? (
                      <button className="btn btn-sm rejectbtn me-2" disabled>
                        Rejected
                      </button>
                    ) : (
                      <button className="btn btn-sm approved-btn me-2" disabled>
                        {request.status}
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};
export default Table;
