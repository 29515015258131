import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GetRegisterCompanies } from "../../../Actions/CompanyActions";
import { SignUpHGO } from "../../../Actions/AuthorizationActions";

const Signuphgo = () => {
  const [accountName, setAccountName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [focalPerson, setFocalPerson] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pkrIban, setPkrIban] = useState("");
  const [pkrAccountTitle, setPkrAccountTitle] = useState("");
  const [pkrBankName, setPkrBankName] = useState("");
  const [pkrBranchName, setPkrBranchName] = useState("");
  const [pkrSwiftCode, setPkrSwiftCode] = useState("");
  const [fcyIban, setFcyIban] = useState("");
  const [fcyAccountTitle, setFcyAccountTitle] = useState("");
  const [fcyBankName, setFcyBankName] = useState("");
  const [fcyBranchName, setFcyBranchName] = useState("");
  const [fcySwiftCode, setFcySwiftCode] = useState("");
  const [e_hajj_iban, setIban] = useState("");

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;

  const [fieldErrors, setFieldErrors] = useState({
    accountName: "",
    name: "",
    email: "",
    phone: "",
    focalPerson: "",
    password: "",
    pkrIban: "",
    pkrAccountTitle: "",
    pkrBankName: "",
    pkrBranchName: "",
    pkrSwiftCode: "",
    fcyIban: "",
    fcyAccountTitle: "",
    fcyBankName: "",
    fcyBranchName: "",
    fcySwiftCode: "",
    e_hajj_iban: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    GetRegisterCompanies(setCompanies);
  }, []);

  const validateFields = () => {
    const errors = {};

    if (!accountName) errors.accountName = "Account Name is required.";
    else if (accountName.length < 2)
      errors.accountName = "Account name must be atleast 2 characters";
    if (!email || !emailRegex.test(email))
      errors.email = "Please enter a valid email address.";
    if (!phone) {
      errors.phone = "This field is required.";
    } else if (phone.length < 6 || !phoneRegex.test(phone)) {
      errors.phone = "Please enter a valid phone. With minimum 6 characters.";
    }
    if (!name) errors.name = "This field is required.";
    else if (name.length < 2)
      errors.name = "HGO Name must be alteast 2 characters.";
    if (!focalPerson) {
      errors.focalPerson = "This field is required.";
    } else if (focalPerson.length < 2) {
      errors.focalPerson = "Focal Person name must be alteast 2 characters.";
    }
    if (!password) errors.password = "This field is required.";
    else if (password.length < 8) {
      errors.password = "Password must be alteast 8 characters.";
    }
    if (!pkrIban) errors.pkrIban = "This field is required.";
    else if (pkrIban.length < 9)
      errors.pkrIban = "PKR Iban must be alteast 9 characters.";
    if (!pkrAccountTitle) errors.pkrAccountTitle = "This field is required.";
    else if (pkrAccountTitle.length < 2)
      errors.pkrAccountTitle = "Account Title must be alteast 2 characters.";
    if (!pkrBankName) errors.pkrBankName = "This field is required.";
    else if (pkrBankName.length < 2)
      errors.pkrBankName = "Bank name must be alteast 2 characters.";
    if (!pkrBranchName) errors.pkrBranchName = "This field is required.";
    else if (pkrBranchName.length < 2)
      errors.pkrBranchName = "Branch name must be alteast 2 characters.";
    if (!pkrSwiftCode) errors.pkrSwiftCode = "This field is required.";
    else if (pkrSwiftCode.length < 4)
      errors.pkrSwiftCode = "PKR Swift code must be alteast 4 characters.";
    if (!fcyIban) errors.fcyIban = "This field is required.";
    else if (fcyIban.length < 9)
      errors.fcyIban = "FCY Iban must be alteast 9 characters.";
    if (!fcyAccountTitle) errors.fcyAccountTitle = "This field is required.";
    else if (fcyAccountTitle.length < 2)
      errors.fcyAccountTitle = "Account title must be alteast 2 characters.";
    if (!fcyBankName) errors.fcyBankName = "This field is required.";
    else if (fcyBankName.length < 2)
      errors.fcyBankName = "Bank name must be alteast 2 characters.";
    if (!fcyBranchName) errors.fcyBranchName = "This field is required.";
    else if (fcyBranchName.length < 2)
      errors.fcyBranchName = "Branch name must be alteast 2 characters.";
    if (!fcySwiftCode) errors.fcySwiftCode = "This field is required.";
    else if (fcySwiftCode.length < 4)
      errors.fcySwiftCode = "FCY Swift code must be alteast 4 characters.";
    if (!e_hajj_iban) errors.e_hajj_iban = "This field is required.";
    else if (e_hajj_iban.length < 7) {
      errors.e_hajj_iban = "EHajj Iban must be alteast 7 characters.";
    }
    setFieldErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleSignupSuccess = () => {
    setAccountName("");
    setName("");
    setEmail("");
    setPhone("");
    setFocalPerson("");
    setPassword("");
    setPkrIban("");
    setPkrAccountTitle("");
    setPkrBankName("");
    setPkrBranchName("");
    setPkrSwiftCode("");
    setFcyIban("");
    setFcyAccountTitle("");
    setFcyBankName("");
    setFcyBranchName("");
    setFcySwiftCode("");
    setIban("");
    setFieldErrors({});
    setTimeout(() => navigate("/"), 2000);
  };

  const handleInputChange = (setter) => (e) => {
    e.preventDefault();
    setter(e.target.value);
    // validateFields();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccessMessage("");
    if (!validateFields()) {
      setLoading(false);
    } else {
      const signupData = {
        monazamUserId: accountName,
        name,
        email,
        phone,
        focalPerson,
        password,
        pkrIban,
        pkrAccountTitle,
        pkrBankName,
        pkrBranchName,
        pkrSwiftCode,
        fcyIban,
        fcyAccountTitle,
        fcyBankName,
        fcyBranchName,
        fcySwiftCode,
        eHajjIban: e_hajj_iban,
        userType: "HGO",
      };
      SignUpHGO(
        signupData,
        setError,
        setLoading,
        handleSignupSuccess,
        setSuccessMessage
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <div className="text-center mb-4">
          <img src="/assets/logo.png" alt="Logo" className="img-fluid logo" />
        </div>

        <form onSubmit={handleSignup}>
          <div className="form-group mb-3">
            <label className="label-text">Monazam Account Name</label>
            <select
              className={`form-control input-style ${
                fieldErrors.accountName ? "border-danger" : ""
              }`}
              value={accountName}
              onChange={handleInputChange(setAccountName)}
            >
              <option value="">Select Company Name</option>
              {companies.length > 0 ? (
                companies.map((company, index) => (
                  <option key={index} value={company?.monazamUser?.id}>
                    {company.name}
                  </option>
                ))
              ) : (
                <option value="">No companies available</option>
              )}
            </select>
            {fieldErrors.accountName && (
              <small className="text-danger">{fieldErrors.accountName}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">HGO Name</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.name ? "border-danger" : ""
              }`}
              placeholder="HGO Name"
              value={name}
              onChange={handleInputChange(setName)}
            />
            {fieldErrors.name && (
              <small className="text-danger">{fieldErrors.name}</small>
            )}
          </div>
          <div className="form-group mb-3 d-flex justify-content-between">
            <div className="w-50 me-2">
              <label className="label-text">Email</label>
              <input
                type="email"
                className={`form-control input-style ${
                  fieldErrors.email ? "border-danger" : ""
                }`}
                placeholder="Email"
                value={email}
                onChange={handleInputChange(setEmail)}
              />
              {fieldErrors.email && (
                <small className="text-danger">{fieldErrors.email}</small>
              )}
            </div>
            <div className="w-50 ms-2">
              <label className="label-text">Phone</label>
              <input
                type="tel"
                className={`form-control input-style ${
                  fieldErrors.phone ? "border-danger" : ""
                }`}
                placeholder="Phone Number"
                value={phone}
                onChange={handleInputChange(setPhone)}
              />
              {fieldErrors.phone && (
                <small className="text-danger">{fieldErrors.phone}</small>
              )}
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="label-text">Focal Person</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.focalPerson ? "border-danger" : ""
              }`}
              placeholder="Focal Person Name"
              value={focalPerson}
              onChange={handleInputChange(setFocalPerson)}
            />
            {fieldErrors.focalPerson && (
              <small className="text-danger">{fieldErrors.focalPerson}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">Password</label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className={`form-control input-style ${
                  fieldErrors.password ? "border-danger" : ""
                }`}
                placeholder="Password"
                value={password}
                onChange={handleInputChange(setPassword)}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y me-2"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {fieldErrors.password && (
              <small className="text-danger">{fieldErrors.password}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">PKR IBAN</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.pkrIban ? "border-danger" : ""
              }`}
              placeholder="PKR IBAN"
              value={pkrIban}
              onChange={handleInputChange(setPkrIban)}
            />
            {fieldErrors.pkrIban && (
              <small className="text-danger">{fieldErrors.pkrIban}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">PKR Account Title</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.pkrAccountTitle ? "border-danger" : ""
              }`}
              placeholder="PKR Account Title"
              value={pkrAccountTitle}
              onChange={handleInputChange(setPkrAccountTitle)}
            />
            {fieldErrors.pkrAccountTitle && (
              <small className="text-danger">
                {fieldErrors.pkrAccountTitle}
              </small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">PKR Bank Name</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.pkrBankName ? "border-danger" : ""
              }`}
              placeholder="PKR Bank Name"
              value={pkrBankName}
              onChange={handleInputChange(setPkrBankName)}
            />
            {fieldErrors.pkrBankName && (
              <small className="text-danger">{fieldErrors.pkrBankName}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">PKR Branch Name</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.pkrBranchName ? "border-danger" : ""
              }`}
              placeholder="PKR Branch Name"
              value={pkrBranchName}
              onChange={handleInputChange(setPkrBranchName)}
            />
            {fieldErrors.pkrBranchName && (
              <small className="text-danger">{fieldErrors.pkrBranchName}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">PKR Swift Code</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.pkrSwiftCode ? "border-danger" : ""
              }`}
              placeholder="PKR Swift Code"
              value={pkrSwiftCode}
              onChange={handleInputChange(setPkrSwiftCode)}
            />
            {fieldErrors.pkrSwiftCode && (
              <small className="text-danger">{fieldErrors.pkrSwiftCode}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">FCY IBAN</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.fcyIban ? "border-danger" : ""
              }`}
              placeholder="FCY IBAN"
              value={fcyIban}
              onChange={handleInputChange(setFcyIban)}
            />
            {fieldErrors.fcyIban && (
              <small className="text-danger">{fieldErrors.fcyIban}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">FCY Account Title</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.fcyAccountTitle ? "border-danger" : ""
              }`}
              placeholder="FCY Account Title"
              value={fcyAccountTitle}
              onChange={handleInputChange(setFcyAccountTitle)}
            />
            {fieldErrors.fcyAccountTitle && (
              <small className="text-danger">
                {fieldErrors.fcyAccountTitle}
              </small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">FCY Bank Name</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.fcyBankName ? "border-danger" : ""
              }`}
              placeholder="FCY Bank Name"
              value={fcyBankName}
              onChange={handleInputChange(setFcyBankName)}
            />
            {fieldErrors.fcyBankName && (
              <small className="text-danger">{fieldErrors.fcyBankName}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">FCY Branch Name</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.fcyBranchName ? "border-danger" : ""
              }`}
              placeholder="FCY Branch Name"
              value={fcyBranchName}
              onChange={handleInputChange(setFcyBranchName)}
            />
            {fieldErrors.fcyBranchName && (
              <small className="text-danger">{fieldErrors.fcyBranchName}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">FCY Swift Code</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.fcySwiftCode ? "border-danger" : ""
              }`}
              placeholder="FCY Swift Code"
              value={fcySwiftCode}
              onChange={handleInputChange(setFcySwiftCode)}
            />
            {fieldErrors.fcySwiftCode && (
              <small className="text-danger">{fieldErrors.fcySwiftCode}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">E-Hajj IBAN</label>
            <input
              type="text"
              className={`form-control input-style ${
                fieldErrors.e_hajj_iban ? "border-danger" : ""
              }`}
              placeholder="E-Hajj IBAN"
              value={e_hajj_iban}
              onChange={handleInputChange(setIban)}
            />
            {fieldErrors.e_hajj_iban && (
              <small className="text-danger">{fieldErrors.e_hajj_iban}</small>
            )}
          </div>
          {error && <div className="text-danger mb-3">{error}</div>}
          {successMessage && (
            <div className="text-success mb-3">{successMessage}</div>
          )}
          <button
            type="submit"
            className="btn btn-primary w-100 green-btn label-text"
            disabled={loading}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </form>
        <div className="text-center mt-3">
          <span className="label-text">Already have an account? </span>
          <a href="/" className="text-decoration-none lightgreen-txt">
            Login Here
          </a>
        </div>
      </div>
    </div>
  );
};

export default Signuphgo;
