import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import axios from "axios";
import {
  ApproveIncomingRequests,
  GetIncomingHGOFilteredRequests,
  GetIncomingHGORequests,
  GetIncomingMonazamFilteredRequests,
  GetIncomingMonazamRequests,
  RejectIncomingRequests,
} from "../../../../../Actions/RequestActions";
import { formatAmount, SuccessToast } from "../../../../../Util";
import { Spinner } from "react-bootstrap";

const Table = ({ reload, filters, setTotalPages, page, setPage }) => {
  const { rollId } = useRollId(); // Use the context to get rollId
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch incoming requests
  const fetchRequests = () => {
    if (!!rollId) {
      setLoading(true);
      setError("");
      if (rollId === "1") {
        console.log("Requesting for monazam");
        GetIncomingMonazamRequests(
          setRequests,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      } else {
        console.log("Requesting for hgo");
        GetIncomingHGORequests(
          setRequests,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      }
    }
  };

  const fetchFilteredRequests = () => {
    if (!!rollId) {
      setLoading(true);
      setError("");
      if (rollId === "1") {
        console.log("Requesting for monazam");
        GetIncomingMonazamFilteredRequests(
          filters,
          setRequests,
          setLoading,
          setError,
          1,
          setTotalPages
        );
      } else {
        console.log("Requesting for hgo");
        GetIncomingHGOFilteredRequests(
          filters,
          setRequests,
          setLoading,
          setError,
          1,
          setTotalPages
        );
      }
      setPage(1);
    }
  };

  const handleApprove = () => {
    SuccessToast("Request approved successfully.");
    fetchRequests();
  };

  const handleReject = () => {
    SuccessToast("Request rejected successfully.");
    fetchRequests();
  };

  const approveRequest = (request) => {
    ApproveIncomingRequests(request.id, setError, handleApprove);
  };

  const rejectRequest = (request) => {
    RejectIncomingRequests(request.id, setError, handleReject);
  };

  useEffect(() => {
    if (!filters.date && !filters.currency) {
      fetchRequests();
    } else {
      fetchFilteredRequests();
    }
  }, [reload, rollId, filters, page]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {rollId === "1" && <th className="tableheader-txt py-3">HGO</th>}
              <th className="tableheader-txt py-3">Date</th>
              <th className="tableheader-txt py-3">Narration</th>
              <th className="tableheader-txt py-3">Type</th>
              <th className="tableheader-txt py-3">Amount</th>
              {rollId === "1" && (
                <th className="tableheader-txt py-3">Action</th>
              )}
              {rollId !== "1" && (
                <th className="tableheader-txt py-3">Status</th>
              )}
            </tr>
          </thead>
          <tbody>
            {requests.map((request, index) => (
              <tr key={index}>
                {rollId === "1" && (
                  <td className="tabledata-txt py-3">{request.hgoUser.name}</td>
                )}
                <td className="tabledata-txt py-3">
                  {new Date(request.date).toISOString().split("T")[0]}
                </td>
                <td className="tabledata-txt py-3">{request.narration}</td>
                <td className="tabledata-txt py-3">{request.currency}</td>
                <td className="tabledata-txt py-3">
                  {formatAmount(request.amount)}
                </td>
                {rollId === "1" && request.status === "pending" ? (
                  <td className="tabledata-txt py-3">
                    <button
                      className="btn btn-sm approved-btn rounded me-2"
                      onClick={() => {
                        approveRequest(request);
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-sm rejectbtn rounded"
                      onClick={() => {
                        rejectRequest(request);
                      }}
                    >
                      Reject
                    </button>
                  </td>
                ) : (
                  <td className="td-cell">
                    {request.status === "rejected" ? (
                      <button className="btn btn-sm rejectbtn me-2" disabled>
                        Rejected
                      </button>
                    ) : (
                      <button className="btn btn-sm approved-btn me-2" disabled>
                        {request.status}
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};
export default Table;
