import React, { useState } from "react";
import { ForgotPassword } from "../../../Actions/AuthorizationActions";
import { SuccessToast } from "../../../Util";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [enrollment, setEnrollment] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSuccess = () => {
    SuccessToast("Temporary password has been sent to your email.");
    setTimeout(() => {
      navigate("/reset-password/" + email);
    }, 2500);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    let temp = {};
    if (!enrollment) {
      temp.enrollment = "This field is required";
    }
    if (enrollment.length < 4) {
      temp.enrollment = "Enrollment should be of atleast 4 characters";
    }
    if (!email) {
      temp.email = "This field is required";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      temp.email = "Enter a valid email";
    }
    if (!temp.enrollment && !temp.email) {
      ForgotPassword(setLoading, email, enrollment, handleSuccess);
      setErrors(temp);
    } else {
      setErrors(temp);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <div className="text-center mb-4">
          <img src="/assets/logo.png" alt="Logo" className="img-fluid logo" />
        </div>
        <form onSubmit={handleResetPassword}>
          <div className="form-group mb-3">
            <label className="label-text">Enrollment</label>
            <input
              type="text"
              className="form-control input-style"
              placeholder="0000"
              error={!!errors?.enrollment}
              value={enrollment}
              onChange={(e) => setEnrollment(e.target.value)}
            />
            {errors?.enrollment && (
              <small className="text-danger">{errors.enrollment}</small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="label-text">Email Address</label>
            <input
              type="email"
              className="form-control input-style"
              placeholder="force@adressemail.com"
              value={email}
              error={!!errors?.email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors?.email && (
              <small className="text-danger">{errors.email}</small>
            )}
          </div>
          <button type="submit" className="btn w-100 green-btn">
            <span className="white-arrow">Reset Password</span>
          </button>
        </form>

        <div className="text-center mt-3">
          <a href="/" className="text-decoration-none lightgreen-txt">
            Back to Login Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
