import { ErrorToast, getConfig } from "../Util";
import Axios from "../WebClient";

export const LoginAction = (
  enrollment,
  password,
  setError,
  handleLoginSuccess
) => {
  Axios.post("/auth/login", {
    enrollment,
    password,
  })
    .then((res) => {
      console.log("Login response: ", res.data);
      handleLoginSuccess(res.data.data);
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const SignUpMonazzam = (
  values,
  setError,
  setLoading,
  setSuccessMessage,
  handleSignupSuccess
) => {
  Axios.post("/auth/register-monazam", values)
    .then((res) => {
      console.log("Signup response: ", res.data);
      setSuccessMessage("Signup successful!");
      setLoading(false);
      handleSignupSuccess();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      setLoading(false);
    });
};

export const SignUpHGO = (
  values,
  setError,
  setLoading,
  handleSignupSuccess,
  setSuccessMessage
) => {
  Axios.post("/auth/register-hgo", values)
    .then((res) => {
      console.log("Signup response: ", res.data);
      setSuccessMessage(
        "Signup successful! Your account is waiting for approval."
      );
      setLoading(false);
      handleSignupSuccess();
    })
    .catch((err) => {
      setLoading(false);
      ErrorToast(err?.response?.data?.message);
      console.error("Error during signup/approval:", err);
    });
};

export const GetRegisteredHGOs = (setError, setHGOs) => {
  Axios.get("/auth/register-hgos", getConfig())
    .then((res) => {
      console.log("Login response: ", res.data);
      setHGOs(res.data.data.data);
    })
    .catch((err) => {
      setError(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const GetPendingHGOs = (
  setError,
  setLoading,
  setHGOs,
  page,
  setTotalPages
) => {
  Axios.get("/auth/pending-hgos?page=" + page, getConfig())
    .then((res) => {
      console.log("Login response: ", res.data);
      setHGOs(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setError(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const GetPendingFilteredHGOs = (
  values,
  setError,
  setLoading,
  setHGOs,
  page,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/auth/pending-hgos?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Login response: ", res.data);
      setHGOs(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setError(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const ApproveHGO = (setError, setLoading, enrollment, handleApprove) => {
  Axios.put("/auth/approve", { enrollment: enrollment }, getConfig())
    .then((res) => {
      console.log("Login response: ", res.data);
      setLoading(false);
      setError("");
      handleApprove();
    })
    .catch((err) => {
      setLoading(false);
      setError(err.response.data.message);
      ErrorToast(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const ForgotPassword = (
  setLoading,
  email,
  enrollment,
  handleSuccess
) => {
  Axios.post("/auth/forgot-password", { enrollment: enrollment, email: email })
    .then((res) => {
      console.log("Login response: ", res.data);
      setLoading(false);
      handleSuccess();
    })
    .catch((err) => {
      setLoading(false);
      ErrorToast(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const ResetPassword = (
  setLoading,
  email,
  tempPass,
  password,
  handleSuccess
) => {
  Axios.put("/auth/reset-password", {
    email: email,
    temporaryPassword: tempPass,
    newPassword: password,
  })
    .then((res) => {
      console.log("Login response: ", res.data);
      setLoading(false);
      handleSuccess();
    })
    .catch((err) => {
      setLoading(false);
      ErrorToast(err.response.data.message);
      console.log("Error: ", err);
    });
};

export const RejectHGO = (setError, setLoading, enrollment, handleReject) => {
  Axios.put("/auth/reject", { enrollment: enrollment }, getConfig())
    .then((res) => {
      console.log("Login response: ", res.data);
      setLoading(false);
      setError("");
      handleReject();
    })
    .catch((err) => {
      setLoading(false);
      setError(err.response.data.message);
      ErrorToast(err.response.data.message);
      console.log("Error: ", err);
    });
};
