import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { Spinner } from "react-bootstrap";
import {
  ApproveMerchantRequests,
  GetMerchantHGOFilteredRequests,
  GetMerchantHGORequests,
  GetMerchantMonazamFilteredRequests,
  GetMerchantMonazamRequests,
  RejectMerchantRequests,
} from "../../../../../Actions/RequestActions";
import { SuccessToast } from "../../../../../Util";
const Table = ({ reload, filters, page, setPage, setTotalPages }) => {
  const { rollId } = useRollId();
  const [data, setData] = useState([]); // State to store fetched data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error messages

  const fetchMerchantRequests = async () => {
    setLoading(true);
    setError("");
    if (!!rollId) {
      if (rollId === "1") {
        GetMerchantMonazamRequests(
          setData,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      } else {
        GetMerchantHGORequests(
          setData,
          setLoading,
          setError,
          page,
          setTotalPages
        );
      }
    }
  };

  const fetchFilteredMerchantRequests = async () => {
    setLoading(true);
    setError("");
    if (!!rollId) {
      if (rollId === "1") {
        GetMerchantMonazamFilteredRequests(
          filters,
          setData,
          setLoading,
          setError,
          1,
          setTotalPages
        );
      } else {
        GetMerchantHGOFilteredRequests(
          filters,
          setData,
          setLoading,
          setError,
          1,
          setTotalPages
        );
      }
    }
    setPage(1);
  };

  const handleApprove = () => {
    SuccessToast("Request approved successfully.");
    fetchMerchantRequests();
  };

  const handleReject = () => {
    SuccessToast("Request rejected successfully.");
    fetchMerchantRequests();
  };

  const approveRequest = (request) => {
    ApproveMerchantRequests(request.id, setError, handleApprove);
  };

  const rejectRequest = (request) => {
    RejectMerchantRequests(request.id, setError, handleReject);
  };

  useEffect(() => {
    if (!filters?.date) {
      fetchMerchantRequests();
    } else {
      fetchFilteredMerchantRequests();
    }
  }, [reload, rollId, filters, page]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        {" "}
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>{" "}
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {rollId === "1" && (
                  <th className="tableheader-txt-merchent">HGO Name</th>
                )}
                <th className="tableheader-txt-merchent">Date</th>
                <th className="tableheader-txt-merchent">Narration</th>
                <th className="tableheader-txt-merchent">Amount</th>
                <th className="tableheader-txt-merchent">Voucher ID</th>
                {rollId === "1" && (
                  <th className="tableheader-txt-merchent">Actions</th>
                )}
                {rollId !== "1" && (
                  <th className="tableheader-txt-merchent">Status</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((request, index) => (
                <tr key={index}>
                  {rollId === "1" && (
                    <td className="tabledata-txt py-3">
                      {request.hgoUser.name || "N/A"}
                    </td>
                  )}
                  <td className="tabledata-txt py-3">
                    {new Date(request.date).toISOString().split("T")[0]}
                  </td>
                  <td className="tabledata-txt py-3">{request.narration}</td>
                  <td className="tabledata-txt py-3">{request.amount}</td>
                  <td className="tabledata-txt py-3">{request.voucherId}</td>
                  {rollId === "1" && request.status === "pending" ? (
                    <td className="tabledata-txt py-3">
                      <button
                        className="btn btn-sm approved-btn rounded me-2"
                        onClick={() => {
                          approveRequest(request);
                        }}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-sm rejectbtn rounded"
                        onClick={() => {
                          rejectRequest(request);
                        }}
                      >
                        Reject
                      </button>
                    </td>
                  ) : request.status === "rejected" ? (
                    <td className="tabledata-txt py-3">
                      <button className="btn btn-sm rejectbtn me-2" disabled>
                        Rejected
                      </button>
                    </td>
                  ) : (
                    <td className="tabledata-txt py-3">
                      <button className="btn btn-sm approved-btn me-2" disabled>
                        {request.status}
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default Table;
