import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import axios from "axios";
import {
  ApproveOutcomingRequests,
  GetOutgoingHGOFilteredRequests,
  GetOutgoingHGORequests,
  GetOutgoingMonazamFilteredRequests,
  GetOutgoingMonazamRequests,
  GetOutgoingRequests,
  RejectOutcomingRequests,
} from "../../../../../Actions/RequestActions";
import { formatAmount, SuccessToast } from "../../../../../Util";
import { Spinner } from "react-bootstrap";
import {
  ApproveHGO,
  GetPendingFilteredHGOs,
  GetPendingHGOs,
  RejectHGO,
} from "../../../../../Actions/AuthorizationActions";

const Table = ({ reload, filters, setTotalPages, page, setPage }) => {
  const { rollId } = useRollId(); // Use the context to get rollId
  const isMonazam = rollId === "1";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = () => {
    SuccessToast("HGO request approved successfully.");
    fetchHgoRequests();
  };

  const handleReject = () => {
    SuccessToast("HGO Request rejected successfully.");
    fetchHgoRequests();
  };

  const approveRequest = (id) => {
    ApproveHGO(setError, setLoading, id, handleApprove);
  };

  const rejectRequest = (id) => {
    RejectHGO(setError, setLoading, id, handleReject);
  };

  // Function to fetch data from the outgoing request API
  const fetchFilteredHgoRequests = async () => {
    setLoading(true);
    GetPendingFilteredHGOs(
      filters,
      setError,
      setLoading,
      setData,
      page,
      setTotalPages
    );
    setPage(1);
  };

  // Function to fetch data from the outgoing request API
  const fetchHgoRequests = async () => {
    setLoading(true);
    GetPendingHGOs(setError, setLoading, setData, page, setTotalPages);
  };

  // Fetch data when the component mounts
  useEffect(() => {
    if (!filters.date) {
      fetchHgoRequests();
    } else {
      fetchFilteredHgoRequests();
    }
  }, [reload, filters, page]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="tableheader-txt py-3">Date</th>
              <th className="tableheader-txt py-3">HGO Name</th>
              <th className="tableheader-txt py-3">Enrollment Number</th>
              <th className="tableheader-txt py-3">
                {isMonazam ? "Actions" : "Status"}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((request, index) => (
              <tr key={index}>
                <td className="tabledata-txt py-3">
                  {new Date(request.createdAt).toISOString().split("T")[0]}
                </td>
                <td className="tabledata-txt py-3">{request.name}</td>
                <td className="tabledata-txt py-3">
                  {request.user.enrollment}
                </td>
                <td className="tabledata-txt pt-3">
                  <button
                    className="btn btn-sm approved-btn me-2"
                    onClick={() => {
                      approveRequest(request.user.enrollment);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-sm rejectbtn"
                    onClick={() => {
                      rejectRequest(request.user.enrollment);
                    }}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};
export default Table;
