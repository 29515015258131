import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { MdCurrencyExchange } from "react-icons/md";
import {
  GetConversionRates,
  SaveConversionRates,
} from "../../../../Actions/RequestActions";

function CurrencyConverter() {
  const [isExpanded, setIsExpanded] = useState(false);

  const [pkrToSar, setPkrToSar] = useState({
    pkr: 75.4,
    sar: 1,
  });

  const [usdToSar, setUsdToSar] = useState({
    usd: 1,
    sar: 3.75,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleInputChange = (id, name, value) => {
    if (id === 1) {
      setPkrToSar({ ...pkrToSar, [name]: value });
    } else {
      setUsdToSar({ ...usdToSar, [name]: value });
    }
  };

  const handleSave = () => {
    SaveConversionRates(pkrToSar, usdToSar);
  };

  const fetchConversionRate = () => {
    console.log("Values: ", pkrToSar, usdToSar);
    GetConversionRates(setPkrToSar, setUsdToSar);
  };

  useEffect(() => {
    fetchConversionRate();
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <h6 className="mb-0 text-sm">Monazam to OPAP</h6>
          <MdCurrencyExchange className="text-secondary" />
        </div>
        <button onClick={toggleExpand} className="btn text-sm">
          {isExpanded ? <FaMinus /> : <FaPlus />}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-2">
          <div
            key={"1"}
            className="conversion d-flex align-items-center gap-2 mb-2"
          >
            <div>
              <label className="mb-1 ms-2 small">PKR</label>
              <input
                className="form-control form-control-sm rounded-lg"
                style={{ width: "80px" }}
                type="number"
                value={pkrToSar.pkr}
                placeholder={"PKR"}
                name="pkr"
                onChange={(e) =>
                  handleInputChange(
                    1,
                    e.target.name,
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
            <div
              className="d-flex align-items-center mt-4 justify-content-center"
              style={{ width: "30px" }}
            >
              <span>=</span>
            </div>
            <div>
              <label className="mb-1 ms-2 small">SAR</label>
              <input
                className="form-control form-control-sm rounded"
                style={{ width: "80px" }}
                type="number"
                value={pkrToSar.sar}
                placeholder={"SAR"}
                name="sar"
                onChange={(e) =>
                  handleInputChange(
                    1,
                    e.target.name,
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
          </div>
          <div
            key={"1"}
            className="conversion d-flex align-items-center gap-2 mb-2"
          >
            <div>
              <label className="mb-1 ms-2 small">USD</label>
              <input
                className="form-control form-control-sm rounded-lg"
                style={{ width: "80px" }}
                type="number"
                value={usdToSar.usd}
                placeholder={"USD"}
                name="usd"
                onChange={(e) =>
                  handleInputChange(
                    2,
                    e.target.name,
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
            <div
              className="d-flex align-items-center mt-4 justify-content-center"
              style={{ width: "30px" }}
            >
              <span>=</span>
            </div>
            <div>
              <label className="mb-1 ms-2 small">SAR</label>
              <input
                className="form-control form-control-sm rounded"
                style={{ width: "80px" }}
                type="number"
                value={usdToSar.sar}
                placeholder={"SAR"}
                name="sar"
                onChange={(e) =>
                  handleInputChange(
                    2,
                    e.target.name,
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
          </div>
          <div className="text-end mt-3">
            <button className="btn btn-success btn-sm" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CurrencyConverter;
