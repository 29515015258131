import React, { useState } from "react";
import Tabs from "../../common_components/Tabs";
import Filters from "./Filterbtn";
import Table from "./Table";
import TableTransfer from "./TableTransfer";
import Pagination from "./Pagination";
import { FaLock, FaPlus } from "react-icons/fa";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { formatAmount } from "../../../../../Util";

const MainContent = ({ rollId }) => {
  const [isTransferMode, setIsTransferMode] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("PKR");
  const [amount, setAmount] = useState(0);

  const handleTransferClick = () => {
    setIsTransferMode((prevMode) => !prevMode);
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };

  return (
    <div className="container-fluid main-content-table bgColor d-flex flex-column overflow-hidden mt-2">
      <hr />

      <div className="d-flex justify-content-start ms-5 d-none">
        <button
          className={`btn transferbtn ${isTransferMode ? "active" : ""}`}
          onClick={handleTransferClick}
          disabled
        >
          Transfers
        </button>
      </div>

      <div className="flex-grow-1 d-flex flex-column pt-2">
        <div className="row mb-4 align-items-start">
          {/* Filters Section */}
          <div className="col-md-6 col-12 d-flex flex-column">
            {isTransferMode && (
              <Filters
                selectedCurrency={selectedCurrency}
                onCurrencySelect={handleCurrencySelect}
                className="my-2"
              />
            )}
          </div>

          {/* Balance and Add Button Section */}
          <div className="col-md-6 col-12 d-flex justify-content-between justify-content-md-end align-items-center mt-3 mt-md-0">
            {rollId === "monazam" && !isTransferMode && (
              <button
                className="d-flex justify-content-center align-items-center me-3 add-button"
                style={{ flexShrink: 0 }}
              >
                <FaPlus className="me-1 greenplusbtn" />
              </button>
            )}
            <div className="balance-box p-2 border-2">
              <div>
                <div className="balance-txt">Balance</div>
                <div className="amount-txt fw-bold">{`SAR ${amount.toLocaleString()}`}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Table Section */}
        <div className="table-wrapper marginRight flex-grow-1 p-3 rounded shadow-sm d-flex flex-column">
          <div
            className="table-container scroll flex-grow-1"
            style={{ maxHeight: "calc(100vh - 250px)" }}
          >
            {isTransferMode ? (
              <TableTransfer />
            ) : (
              <Table setAmount={setAmount} />
            )}
          </div>

          {/* Pagination and Export Button */}
          <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
            {/* <button className="btn modal-label-txt modal-btn-bg d-flex align-items-center">
              Export <FaLock className="ms-2" />
            </button> */}
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
