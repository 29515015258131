import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import RollIdPrompt from "../common_components/RollIdPrompt";
import Sidebar from "../common_components/Sidebar";
import MainContent from "./IncomingComponents/MainContent";
import { RollIdProvider } from "../common_components/RollIdContext"; // Import the provider
import Tabs from "../common_components/Tabs";

const IncomingDashboard = () => {
  const navigate = useNavigate();
  const { userType } = useParams(); // Get the user type from the URL
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [reload, setReload] = useState(0);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    let userTypeStorage = localStorage.getItem("user_Type");
    if (!userType || (userType !== "hgo" && userType !== "monazam")) {
      // Redirect to an error page or a default page if the userType is invalid
      navigate("/");
    } else if (userType.toLowerCase() !== userTypeStorage.toLowerCase()) {
      navigate("/error_page");
    }
  }, [userType]);

  return (
    <RollIdProvider>
      {" "}
      {/* Wrap everything inside the RollIdProvider */}
      <div className="dashboard-layout d-flex">
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          setReload={setReload}
          reload={reload}
        />
        <div className={`main-content  ${isSidebarOpen ? "blurred" : ""}`}>
          {/* Hamburger Menu for mobile screens */}
          <div className="d-md-none d-flex justify-content-between align-items-center sidebar-logo-bg py-3 px-1">
            <button className="btn" onClick={toggleSidebar}>
              {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            <img
              src="/assets/logo.png"
              alt="Logo"
              className="img-fluid logo pe-5"
              style={{ height: "40px" }}
            />
          </div>

          {/* RollIdPrompt to set the roll ID */}
          <RollIdPrompt />
          <div className="me-3 mt-3">
            <Tabs />
          </div>
          {/* Main content */}
          <MainContent reload={reload} />
        </div>
      </div>
    </RollIdProvider>
  );
};

export default IncomingDashboard;
