import React, { useState } from "react";
import Tabs from "../../common_components/Tabs";
import Filters from "./Filterbtn";
import Table from "./Table";
import Pagination from "./Pagination";
import { useRollId } from "../../common_components/RollIdContext"; // Import the useRollId hook

const MainContent = ({ reload }) => {
  const { rollId } = useRollId(); // Get rollId directly from context
  const [filters, setFilters] = useState({
    date: "",
    categoryType: "",
    currency: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTP] = useState(0);
  return (
    <div className="container-fluid main-content-table bgColor d-flex flex-column overflow-hidden mt-2">
      <div className="flex-grow-1 d-flex flex-column pt-2">
        <div className="mt-2">
          <Filters filters={filters} setFilters={setFilters} />
        </div>
        <div
          className="table-wrapper marginRight flex-grow-1 p-3  rounded shadow-sm d-flex flex-column"
          style={{ maxHeight: "calc(100vh - 420px)" }}
        >
          <div className="table-container scroll flex-grow-1">
            <Table
              reload={reload}
              filters={filters}
              setTotalPages={setTP}
              page={page}
              setPage={setPage}
            />
          </div>
          <div className="pagination-container mt-2 me-3">
            <Pagination totalPages={totalPages} page={page} setPage={setPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
