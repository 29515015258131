import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { GetMonazamLedgerTransactions } from "../../../../../Actions/LedgerActions";
import { formatAmount } from "../../../../../Util";
import { FaPencilAlt } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

const RenderUser = ({ user, currency }) => {
  useEffect(() => {
    console.log("Rendering user: ", user);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown-section mt-4">
      <div className="flex justify-content-between items-center">
        {/* {rollId === "monazam" && (
          <img
            src="/assets/editsymbol.png"
            alt="Edit Symbol"
            className="img-fluid mr-2 edit-icon"
          />
        )} */}
        <div>
          <span className=" fw-bold" style={{ fontSize: "12px" }}>
            {user.name}
          </span>
          <FaPencilAlt
            size={13}
            style={{ color: "#198754", marginLeft: "7px", cursor: "pointer" }}
          />
        </div>
        <button
          className="bg-white text-black textSizeIcon"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "-" : "+"}
        </button>
      </div>

      {open && (
        <table className="table mt-2 table-borderless">
          <tbody>
            {user.transactions.map((transaction) => {
              return (
                <>
                  {transaction.type === "opap" ? (
                    <tr>
                      <td className="tabledata-txt py-3">
                        {transaction.date?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt py-3">Bank Charges</td>
                      <td className="tabledata-txt py-3"></td>
                      <td className="tabledata-txt py-3">
                        {formatAmount(transaction.bankCredit)}
                      </td>
                      <td className="tabledata-txt py-3">
                        {formatAmount(transaction.balance)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td className="tabledata-txt py-3">
                      {transaction.date?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt py-3">
                      {transaction.narration}
                    </td>
                    <td className="tabledata-txt py-3">
                      {formatAmount(transaction.debit)}
                    </td>
                    <td className="tabledata-txt py-3">
                      {formatAmount(transaction.credit)}
                    </td>
                    <td className="tabledata-txt py-3">
                      {transaction.type === "opap"
                        ? formatAmount(
                            parseFloat(transaction.balance) +
                              parseFloat(transaction.bankCredit)
                          )
                        : formatAmount(transaction.balance)}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const Table = ({ setAmount, currency }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchTransactions = () => {
    setLoading(true);
    GetMonazamLedgerTransactions(
      setTransactions,
      setUsers,
      setAmount,
      currency,
      setLoading,
      setError
    );
  };

  useEffect(() => {
    fetchTransactions();
  }, [currency]);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-borderless ">
            <thead>
              <tr>
                <th className="tableheader-txt">Date</th>
                <th className="tableheader-txt">Narration</th>
                <th className="tableheader-txt">Debit</th>
                <th className="tableheader-txt">Credit</th>
                <th className="tableheader-txt">Balance</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((transaction) => {
                return (
                  <>
                    {!!transaction.accumulativeRequestId ? (
                      <tr>
                        <td className="tabledata-txt py-3">
                          {transaction.date?.split("T")[0]}
                        </td>
                        <td className="tabledata-txt py-3">Bank Charges</td>
                        <td className="tabledata-txt py-3"></td>
                        <td className="tabledata-txt py-3">
                          {formatAmount(transaction.bankCredit)}
                        </td>
                        <td className="tabledata-txt py-3">
                          {currency === "PKR"
                            ? formatAmount(transaction.pkrMonazamLedgerAmount)
                            : formatAmount(transaction.usdMonazamLedgerAmount)}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <tr>
                      <td className="tabledata-txt py-3">
                        {transaction.date?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt py-3">
                        {transaction.narration}
                      </td>
                      <td className="tabledata-txt py-3">
                        {formatAmount(transaction.debit)}
                      </td>
                      <td className="tabledata-txt py-3">
                        {formatAmount(transaction.credit)}
                      </td>
                      <td className="tabledata-txt py-3">
                        {!!transaction.accumulativeRequestId
                          ? currency === "PKR"
                            ? formatAmount(
                                parseFloat(transaction.pkrMonazamLedgerAmount) +
                                  parseFloat(transaction.bankCredit)
                              )
                            : formatAmount(
                                parseFloat(transaction.usdMonazamLedgerAmount) +
                                  parseFloat(transaction.bankCredit)
                              )
                          : currency === "USD"
                          ? formatAmount(
                              !!transaction.usdMonazamLedgerAmount
                                ? transaction.usdMonazamLedgerAmount
                                : transaction.balance
                            )
                          : formatAmount(
                              !!transaction.pkrMonazamLedgerAmount
                                ? transaction.pkrMonazamLedgerAmount
                                : transaction.balance
                            )}
                      </td>
                    </tr>
                  </>
                );
              })}
              {/* <tr>
              <td className="tabledata-txt">02-01-2024</td>
              <td className="tabledata-txt">Transfer</td>
              <td className="tabledata-txt">PKR</td>
              <td className="tabledata-txt">5000</td>
              <td className="tabledata-txt">V002</td>
              <td className="tabledata-txt">Pending</td>
              {rollId === "1" && (
                <td className="tabledata-txt">
                  <button className="btn btn-sm me-2 btn-compact">
                    Approve
                  </button>
                  <button className="btn btn-sm btn-compactreject">
                    Reject
                  </button>
                </td>
              )}
            </tr> */}
            </tbody>
          </table>
          {users.map((tuser) => {
            return (
              <>
                <RenderUser user={tuser} currency={currency} />
                <hr />
              </>
            );
          })}
          {/* <div className="dropdown-section mt-4">
          <div className="flex justify-between items-center">
            {rollId === "monazam" && (
              <img
                src="/assets/editsymbol.png"
                alt="Edit Symbol"
                className="img-fluid mr-2 edit-icon"
              />
            )}
            <span className="hgo-text">HGO2</span>
            <button className="text-lg plus-minus-btn" onClick={toggleHGO2}>
              {showHGO2 ? "-" : "+"}
            </button>
          </div>
          {showHGO2 && (
            <table className="table mt-2">
              <thead>
                <tr>
                  <th className="tableheader-txt">Date</th>
                  <th className="tableheader-txt">Narration</th>
                  <th className="tableheader-txt">Currency</th>
                  <th className="tableheader-txt">Amount</th>
                  <th className="tableheader-txt">VoucherID</th>
                  <th className="tableheader-txt">Status</th>
                  {rollId === "monazam" && (
                    <th className="tableheader-txt">Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tabledata-txt">05-01-2024</td>
                  <td className="tabledata-txt">Payment</td>
                  <td className="tabledata-txt">USD</td>
                  <td className="tabledata-txt">1200</td>
                  <td className="tabledata-txt">V005</td>
                  <td className="tabledata-txt">Completed</td>
                  {rollId === "monazam" && (
                    <td className="tabledata-txt">
                      <button className="btn btn-sm btn-compact me-2">
                        Approve
                      </button>
                      <button className="btn btn-sm btn-compactreject">
                        Reject
                      </button>
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="tabledata-txt">06-01-2024</td>
                  <td className="tabledata-txt">Transfer</td>
                  <td className="tabledata-txt">PKR</td>
                  <td className="tabledata-txt">2500</td>
                  <td className="tabledata-txt">V006</td>
                  <td className="tabledata-txt">Pending</td>
                  {rollId === "monazam" && (
                    <td className="tabledata-txt">
                      <button className="btn btn-sm btn-compact me-2">
                        Approve
                      </button>
                      <button className="btn btn-sm btn-compactreject">
                        Reject
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div> */}
        </div>
      </div>
    );
};

export default Table;
