import { ErrorToast, getConfig } from "../Util";
import Axios from "../WebClient";

export const GetMonazamLedgerTransactions = (
  setTransaction,
  setUsers,
  setAmount,
  currency,
  setLoading,
  setError
) => {
  Axios.get("/ledger/transactions?type=monazam", getConfig())
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      console.log("Currency: ", currency);
      let transactions = res.data.data.transactions;
      let users = res.data.data.users;
      if (currency === "PKR") {
        setAmount(res.data.data.pkrMonazamLedgerBalance);
        setTransaction(
          transactions.filter((transaction) => {
            return transaction.currency === "PKR";
          })
        );
        setUsers(
          users.map((user) => {
            let transactions = user.transactions.filter(
              (transaction) => transaction.currency === "PKR"
            );
            user.transactions = transactions;
            return user;
          })
        );
      } else if (currency === "USD") {
        setAmount(res.data.data.usdMonazamLedgerBalance);
        setTransaction(
          transactions.filter((transaction) => {
            return transaction.currency === "USD";
          })
        );
        setUsers(
          users.map((user) => {
            let transactions = user.transactions.filter(
              (transaction) => transaction.currency === "USD"
            );
            user.transactions = transactions;
            return user;
          })
        );
      }
      setError("");
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      ErrorToast(err?.response?.data?.message);
    });
};

export const GetOpapLedgerTransactions = (
  setTransaction,
  setUsers,
  setAmount,
  setLoading,
  setError
) => {
  Axios.get("/ledger/transactions?type=opap", getConfig())
    .then((res) => {
      setAmount(res.data.data.sarOpapLedgerBalance);
      setTransaction(res.data.data.transactions);
      setUsers(res.data.data.users);
      setError("");
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data");
      ErrorToast(err?.response?.data?.message);
    });
};

export const GetEHajjLedgerTransactions = (
  setTransaction,
  setUsers,
  setAmount,
  setLoading,
  setError
) => {
  Axios.get("/ledger/transactions?type=ehajj", getConfig())
    .then((res) => {
      setAmount(res.data.data.sarEhajjLedgerBalance);
      setTransaction(res.data.data.transactions);
      setUsers(res.data.data.users);
      setError("");
      setLoading(false);
    })
    .catch((err) => {
      setError("Error fetching data");
      setLoading(false);
      ErrorToast(err?.response?.data?.message);
    });
};

export const CreateAccumulativeRequest = (
  values,
  rows,
  category,
  date,
  currency,
  setLoading,
  setError,
  handleCreate
) => {
  Axios.post(
    "/ledger/accumulative-request",
    {
      ...values,
      categoryType: category,
      date: date.toLocaleString(),
      currency,
      users: rows.map((row) => {
        return { id: row.id, amount: row.amount };
      }),
    },
    getConfig()
  )
    .then((res) => {
      console.log("Create request response: ", res.data);
      setLoading(false);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};
