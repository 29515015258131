import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ResetPassword } from "../../../Actions/AuthorizationActions";
import { ErrorToast, SuccessToast, WarningToast } from "../../../Util";

const ResetPassword1 = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setCP] = useState("");
  const [tempPassword, setTP] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { email } = useParams();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleReset = () => {
    SuccessToast("Password reset successfully.");
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, [3000]);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    let temp = {};
    if (!password) {
      temp.password = "This field is required";
    } else if (password?.length < 8) {
      temp.password = "Password's length should be atleast 8 characters";
    }
    if (!tempPassword) {
      temp.tempPassword = "This field is required";
    } else if (tempPassword?.length < 5) {
      temp.tempPassword =
        "Temporary password's length should be atleast 5 characters";
    }
    if (password !== confirmPassword) {
      temp.confPass = "Passwords do not match";
    }

    if (!temp.password && !temp.confPass && !temp.tempPassword) {
      setErrors(temp);
      ResetPassword(setLoading, email, tempPassword, password, handleReset);
    } else {
      setErrors(temp);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <div className="text-center mb-4">
          <img src="/assets/logo.png" alt="Logo" className="img-fluid logo" />
        </div>

        <form onSubmit={handleResetPassword}>
          <div className="form-group mb-3 position-relative">
            <label className="label-text">Temporary Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control input-style"
              placeholder="password"
              error={!!errors.tempPassword}
              value={tempPassword}
              onChange={(e) => setTP(e.target.value)}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="eye-icon"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
            {errors?.tempPassword && (
              <small className="text-danger">{errors.tempPassword}</small>
            )}
          </div>
          <div className="form-group mb-3 position-relative">
            <label className="label-text">Create New Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control input-style"
              placeholder="password"
              error={!!errors.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="eye-icon"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
            {errors?.password && (
              <small className="text-danger">{errors.password}</small>
            )}
          </div>
          <div className="form-group mb-3 position-relative">
            <label className="label-text">Confirm New Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control input-style"
              placeholder="password"
              error={!!errors.confPass}
              value={confirmPassword}
              onChange={(e) => setCP(e.target.value)}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="eye-icon"
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
            {errors?.confPass && (
              <small className="text-danger">{errors.confPass}</small>
            )}
          </div>

          <button type="submit" className="btn w-100 green-btn">
            <span
              className="white-arrow"
              onClick={(e) => {
                handleResetPassword(e);
              }}
            >
              Save
            </span>
          </button>
        </form>

        <div className="text-center mt-3">
          <a href="/" className="text-decoration-none lightgreen-txt">
            Back to Login Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword1;
