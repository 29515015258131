import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { CreateIncomingRequests } from "../../../../Actions/RequestActions";
import { getTimeAddedDate, SuccessToast, WarningToast } from "../../../../Util";

const defaultValues = {
  date: "",
  narration: "",
  amount: null,
  currency: "",
};

const IncomingRequest = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState(defaultValues);
  const [message, setMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleCreate = () => {
    SuccessToast("Incoming request created successfully!");
    setFormData(defaultValues);
    setValidationErrors({});
    onClose("create");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      let value = e.target.value;
      value = value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/(\.\d{2})\d+/, "$1");
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseFloat(value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setValidationErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    const { date, narration, currency, amount } = formData;

    if (!date) errors.date = true;
    if (!narration?.trim()) errors.narration = true;
    if (!currency) errors.currency = true;
    if (!amount || amount <= 0) errors.amount = true;

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      CreateIncomingRequests(
        {
          ...formData,
          date: getTimeAddedDate(formData.date),
          type: "incoming",
        },
        setMessage,
        handleCreate
      );
    } else {
      WarningToast("All fields are required.");
    }
  };

  if (!isOpen) return <></>;

  return (
    <div className="modal d-block modal-blur-bg">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="px-5 modal-content">
          <button
            className="btn modal-label-txt modal-btn-bg btn-success position-absolute top-0 end-0 m-3"
            onClick={() => {
              onClose("close");
            }}
          >
            Close <FaTimes className="me-1" />
          </button>

          <div className="modal-header">
            <h2 className="modal-title mx-auto modal-heading-txt">
              Incoming Request
            </h2>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-between mb-3">
              <div className="w-50">
                <label className="modal-label-txt">Date *</label>
                <input
                  type="date"
                  name="date"
                  className="form-control modal-input-txt"
                  value={formData.date}
                  onChange={handleChange}
                  style={{
                    width: "95%",
                    borderColor: validationErrors.date ? "red" : "",
                    backgroundColor: validationErrors.date ? "#fff8f8" : "",
                  }}
                />
              </div>
              <div className="w-50">
                <label className="modal-label-txt">Currency *</label>
                <select
                  name="currency"
                  className="form-select modal-input-txt"
                  value={formData.currency}
                  onChange={handleChange}
                  style={{
                    width: "95%",
                    borderColor: validationErrors.currency ? "red" : "",
                    backgroundColor: validationErrors.currency ? "#fff8f8" : "",
                  }}
                >
                  <option value="">Select</option>
                  <option value="PKR">PKR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label className="modal-label-txt">Narration *</label>
              <textarea
                name="narration"
                className="form-control modal-input-txt"
                rows="3"
                placeholder="comments"
                value={formData.narration}
                onChange={handleChange}
                style={{
                  width: "47.5%",
                  borderColor: validationErrors.narration ? "red" : "",
                  backgroundColor: validationErrors.narration ? "#fff8f8" : "",
                }}
              />
            </div>

            <div className="mb-3">
              <label className="modal-label-txt">Amount *</label>
              <input
                type="number"
                name="amount"
                className="form-control modal-input-txt"
                placeholder="20,500"
                value={formData.amount}
                onChange={handleChange}
                style={{
                  width: "47.5%",
                  borderColor: validationErrors.amount ? "red" : "",
                  backgroundColor: validationErrors.amount ? "#fff8f8" : "",
                }}
              />
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              className="btn w-50 modal-label-txt modal-btn-bg"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>

          {message && (
            <div
              className={`alert mt-3 ${
                message.type === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              {message.text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IncomingRequest;
