import React from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the useRollId hook

const Filterbtn = ({ filters, setFilters }) => {
  const { rollId } = useRollId(); // Get rollId from context

  const handleFilterApply = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="ms-4 mb-4 d-flex">
      {rollId === "monazam" && (
        <div className="dropdown me-2">
          <button
            className="btn shadow filter-btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            id="hgoDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            HGO
          </button>
          <ul className="dropdown-menu" aria-labelledby="hgoDropdown">
            <li>
              <a className="dropdown-item" href="#">
                HGO Option
              </a>
            </li>
          </ul>
        </div>
      )}

      <div className="dropdown me-2">
        <button
          className="btn marginRight filter-btn btn-sm btn-outline-secondary dropdown-toggle"
          type="button"
          id="dateDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {!!filters.date ? filters.date : "Date"}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dateDropdown">
          <li>
            <input
              type="date"
              value={filters.date}
              onChange={(e) => {
                handleFilterApply("date", e.target.value);
              }}
            />
          </li>
        </ul>
      </div>

      <div className="dropdown me-2">
        <button
          className="btn marginRight filter-btn btn-sm btn-outline-secondary dropdown-toggle"
          type="button"
          id="currencyDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {!!filters.currency ? filters.currency : "Currency"}
        </button>
        <ul className="dropdown-menu" aria-labelledby="currencyDropdown">
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                handleFilterApply("currency", "PKR");
              }}
            >
              PKR
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                handleFilterApply("currency", "USD");
              }}
            >
              USD
            </a>
          </li>
        </ul>
      </div>

      <div className="dropdown">
        <button
          className="btn filter-btn btn-sm btn-outline-secondary dropdown-toggle"
          type="button"
          id="typeDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {!!filters.categoryType ? filters.categoryType : "Type"}
        </button>
        <ul className="dropdown-menu" aria-labelledby="typeDropdown">
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                handleFilterApply("categoryType", "MonazamTransfer");
              }}
            >
              Monazam to Opap Transfer
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                handleFilterApply("categoryType", "OpapTransfer");
              }}
            >
              Opap to Ehajj Transfer
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Filterbtn;
