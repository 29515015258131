import { ErrorToast, getConfig, SuccessToast } from "../Util";
import Axios from "../WebClient";

export const GetIncomingMonazamRequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/monazam/incoming?page=" + page, getConfig())
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetIncomingMonazamFilteredRequests = (
  values,
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/request/monazam/incoming?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetIncomingHGORequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/hgo/incoming?page=" + page, getConfig())
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetIncomingHGOFilteredRequests = (
  values,
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/request/hgo/incoming?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetMerchantMonazamRequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/monazam/merchant?page=" + page, getConfig())
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetMerchantMonazamFilteredRequests = (
  values,
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/request/monazam/merchant?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      console.log("Error: ", err);
    });
};

export const GetMerchantHGORequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/hgo/merchant?page=" + page, getConfig())
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      console.log("Error: ", err);
    });
};

export const GetMerchantHGOFilteredRequests = (
  values,
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/request/hgo/merchant?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Incoming requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      // setError(err.message);
      console.log("Error: ", err);
    });
};

export const GetOutgoingMonazamRequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/monazam/outgoing?page=" + page, getConfig())
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetOutgoingMonazamFilteredRequests = (
  values,
  setRequests,
  page,
  setLoading,
  setError,
  setTotalPages
) => {
  let params = "";
  {
    !!values?.status
      ? (params += `status=${values.status}&`)
      : (params = params);
  }
  {
    !!values?.categoryType
      ? (params += `categoryType=${values.categoryType}&`)
      : (params = params);
  }
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }
  Axios.get(
    "/request/monazam/outgoing?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetOutgoingHGOFilteredRequests = (
  values,
  setRequests,
  page,
  setTotalPages,
  setLoading,
  setError
) => {
  let params = "";
  {
    !!values?.status
      ? (params += `status=${values.status}&`)
      : (params = params);
  }
  {
    !!values?.categoryType
      ? (params += `categoryType=${values.categoryType}&`)
      : (params = params);
  }
  {
    !!values?.currency
      ? (params += `currency=${values.currency}&`)
      : (params = params);
  }
  {
    !!values?.date ? (params += `date=${values.date}&`) : (params = params);
  }

  Axios.get(
    "/request/hgo/outgoing?page=" +
      page +
      "&" +
      params.substring(0, params.length - 1),
    getConfig()
  )
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const GetOutgoingHGORequests = (
  setRequests,
  setLoading,
  setError,
  page,
  setTotalPages
) => {
  Axios.get("/request/hgo/outgoing?page=" + page, getConfig())
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      setRequests(res.data.data.data);
      setTotalPages(res.data.data.pagination.totalPages);
      setLoading(false);
      setError("");
    })
    .catch((err) => {
      setLoading(false);
      setError("Error fetching data.");
      console.log("Error: ", err);
    });
};

export const CreateOutgoingRequests = (values, setError, handleCreate) => {
  Axios.post("/request/outgoing", values, getConfig())
    .then((res) => {
      console.log("Create Outgoing requests response: ", res.data);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const CreateIncomingRequests = (values, setError, handleCreate) => {
  Axios.post("/request/incoming", values, getConfig())
    .then((res) => {
      console.log("Outgoing requests response: ", res.data);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err?.response?.data?.message);
    });
};

export const CreateMerchantRequests = (values, setError, handleCreate) => {
  Axios.post("/request/merchant", values, getConfig())
    .then((res) => {
      console.log("Merchant requests response: ", res.data);
      handleCreate();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err?.response?.data?.message);
    });
};

export const ApproveIncomingRequests = (id, setError, handleApprove) => {
  Axios.put("/request/incoming/approve/" + id, {}, getConfig())
    .then((res) => {
      console.log("Approve request response: ", res.data);
      handleApprove();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const RejectIncomingRequests = (id, setError, handleReject) => {
  Axios.put("/request/incoming/reject/" + id, {}, getConfig())
    .then((res) => {
      console.log("Reject request response: ", res.data);
      handleReject();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const ApproveMerchantRequests = (id, setError, handleApprove) => {
  Axios.put("/request/merchant/approve/" + id, {}, getConfig())
    .then((res) => {
      console.log("Approve request response: ", res.data);
      handleApprove();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const RejectMerchantRequests = (id, setError, handleReject) => {
  Axios.put("/request/merchant/reject/" + id, {}, getConfig())
    .then((res) => {
      console.log("Reject request response: ", res.data);
      handleReject();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const ApproveOutcomingRequests = (id, setError, handleApprove) => {
  Axios.put("/request/outgoing/approve/" + id, {}, getConfig())
    .then((res) => {
      console.log("Approve request response: ", res.data);
      handleApprove();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const RejectOutcomingRequests = (id, setError, handleReject) => {
  Axios.put("/request/outgoing/reject/" + id, {}, getConfig())
    .then((res) => {
      console.log("Reject request response: ", res.data);
      handleReject();
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const GetConversionRates = (setPkrRate, setUsdRate) => {
  Axios.get("/conversion-rate", getConfig())
    .then((res) => {
      console.log("Conversion rate response: ", res.data);
      let pkrRate = res.data.data.ratePKRToSAR;
      let usdRate = res.data.data.rateUSDToSAR;
      if (pkrRate >= 1) {
        setPkrRate({
          pkr: 1,
          sar: pkrRate,
        });
      } else {
        setPkrRate({
          pkr: 1 / pkrRate,
          sar: 1,
        });
      }
      if (usdRate >= 1) {
        setUsdRate({
          usd: 1,
          sar: usdRate,
        });
      } else {
        setUsdRate({
          usd: 1 / usdRate,
          sar: 1,
        });
      }
      // setValues(res.data.data);
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};

export const SaveConversionRates = (pkrRateValues, usdRateValues) => {
  Axios.post(
    "/conversion-rate",
    {
      ratePKRToSAR: pkrRateValues.sar / pkrRateValues.pkr,
      rateUSDToSAR: usdRateValues.sar / usdRateValues.usd,
    },
    getConfig()
  )
    .then((res) => {
      console.log("Conversion rate response: ", res.data);
      SuccessToast("Conversion rates updated successfully");
      // setValues(res.data.data);
    })
    .catch((err) => {
      ErrorToast(err?.response?.data?.message);
      console.log("Error: ", err);
    });
};
