import React, { useState, useEffect } from "react";
import { FaBell, FaBars } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "./Notification";
import { Link } from "react-router-dom";

const Tabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userType, setUserType] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const storedUserType = localStorage.getItem("user_Type");
    if (!storedUserType) {
      handleLogout();
    } else {
      setUserType(storedUserType);
      setActiveTab(location.pathname);
    }
  }, [location]);

  const handleBellClick = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="d-flex justify-content-end align-items-center me-2">
      <ul className="nav ResponsiveTabs align-items-center">
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/incoming") ? "active" : ""
            }`}
            to={`/incoming/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Incoming
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/outgoing") ? "active" : ""
            }`}
            to={`/outgoing/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Outgoing
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/monazamaccount") ? "active" : ""
            }`}
            to={`/monazamaccount/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Monazam Account
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/opap") ? "active" : ""
            }`}
            to={`/opap/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            OPAP Account
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/ehajj") ? "active" : ""
            }`}
            to={`/ehajj/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            E-Hajj Account
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link tab-link ${
              location.pathname.includes("/merchant") ? "active" : ""
            }`}
            to={`/merchant/${userType?.toLowerCase()}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Merchant
          </Link>
        </li>
        {userType !== "HGO" && (
          <li className="nav-item">
            <Link
              className={`nav-link tab-link ${
                location.pathname.includes("/hgorequest") ? "active" : ""
              }`}
              to={`/hgorequest/${userType?.toLowerCase()}`}
              onClick={() => setIsMenuOpen(false)}
            >
              HGO Requests
            </Link>
          </li>
        )}
        <li className="nav-item">
          <button className="nav-link tab-link" onClick={handleLogout}>
            Logout
          </button>
        </li>
        <li className="nav-item me-3">
          <FaBell
            size={20}
            className="bell-icon"
            onClick={handleBellClick}
            style={{ cursor: "pointer" }}
          />
          <Notification isOpen={isModalOpen} onClose={closeModal} />
        </li>
      </ul>
    </div>
  );
};
export default Tabs;
