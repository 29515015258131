import React, { useEffect, useState } from "react";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook
import { GetEHajjLedgerTransactions } from "../../../../../Actions/LedgerActions";
import { formatAmount } from "../../../../../Util";
import { FaPencilAlt } from "react-icons/fa";
import { Spinner } from "react-bootstrap";

const RenderUser = ({ user }) => {
  useEffect(() => {
    console.log("Rendering user: ", user);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="dropdown-section mt-4">
      <div className="flex justify-content-between items-center">
        {/* {rollId === "monazam" && (
          <img
            src="/assets/editsymbol.png"
            alt="Edit Symbol"
            className="img-fluid mr-2 edit-icon"
          />
        )} */}
        <div>
          <span className=" fw-bold" style={{ fontSize: "12px" }}>
            {user.name}
          </span>
          <FaPencilAlt
            size={13}
            style={{ color: "#198754", marginLeft: "7px", cursor: "pointer" }}
          />
        </div>
        <button
          className="bg-white text-black textSizeIcon"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "-" : "+"}
        </button>
      </div>
      {open && (
        <table className="table mt-2 table-borderless">
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "40%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <tbody>
            {user.transactions.map((transaction) => {
              return (
                <>
                  {/* {transaction.ledgerType === "ehajj" ? (
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.date?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">Bank Charges</td>
                      <td className="tabledata-txt"></td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.bankCreditSAR)}
                      </td>
                      <td className="tabledata-txt">
                        {formatAmount(transaction.sarEhajjLedgerAmount)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )} */}
                  <tr>
                    <td className="tabledata-txt">
                      {transaction.date?.split("T")[0]}
                    </td>
                    <td className="tabledata-txt">{transaction.narration}</td>
                    <td className="tabledata-txt">
                      {transaction.type === "ehajj"
                        ? formatAmount(transaction.creditSAR)
                        : ""}
                    </td>
                    <td className="tabledata-txt">
                      {transaction.type === "ehajj"
                        ? ""
                        : formatAmount(transaction.creditSAR)}
                    </td>
                    <td className="tabledata-txt">
                      {formatAmount(transaction.sarEhajjLedgerAmount)}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const Table = ({ setAmount }) => {
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchTransactios = () => {
    setLoading(true);
    GetEHajjLedgerTransactions(
      setTransactions,
      setUsers,
      setAmount,
      setLoading,
      setError
    );
  };

  useEffect(() => {
    fetchTransactios();
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" style={{ color: "#198754" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  else if (!!error) return <p>{error}</p>;
  else
    return (
      <div>
        <div className="table-responsive">
          <table className="table table-borderless">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "40%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr>
                <th className="tableheader-txt">Date</th>
                <th className="tableheader-txt">Narration</th>
                <th className="tableheader-txt">Debit</th>
                <th className="tableheader-txt">Credit</th>
                <th className="tableheader-txt">Balance</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((transaction) => {
                return (
                  <>
                    {/* {transaction.ledgerType === "ehajj" ? (
                      <tr>
                        <td className="tabledata-txt">
                          {transaction.date?.split("T")[0]}
                        </td>
                        <td className="tabledata-txt">Bank Charges</td>
                        <td className="tabledata-txt"></td>
                        <td className="tabledata-txt">
                          {formatAmount(transaction.bankCredit)}
                        </td>
                        <td className="tabledata-txt">
                          {formatAmount(transaction.pkrMonazamLedgerAmount)}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )} */}
                    <tr>
                      <td className="tabledata-txt">
                        {transaction.date?.split("T")[0]}
                      </td>
                      <td className="tabledata-txt">{transaction.narration}</td>
                      <td className="tabledata-txt">
                        {transaction.ledgerType === "opap"
                          ? formatAmount(transaction.creditSar)
                          : ""}
                      </td>
                      <td className="tabledata-txt">
                        {transaction.ledgerType === "opap"
                          ? ""
                          : formatAmount(transaction.credit)}
                      </td>
                      <td className="tabledata-txt">
                        {/* {transaction.ledgerType === "ehajj"
                          ? formatAmount(
                              parseFloat(transaction.sarEhajjLedgerAmount) +
                                parseFloat(transaction.bankCreditSAR)
                            )
                          : formatAmount(transaction.sarEhajjLedgerAmount)} */}
                        {formatAmount(transaction.sarEhajjLedgerAmount)}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>

          {users.map((tuser) => {
            return (
              <>
                <RenderUser user={tuser} />
                <hr />
              </>
            );
          })}
        </div>
      </div>
    );
};

export default Table;
