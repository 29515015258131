import { toast } from "react-toastify";

export function formatAmount(amount) {
  if (!!amount) {
    if (typeof amount === "string") {
      return parseFloat(amount)?.toLocaleString();
    } else {
      return amount?.toLocaleString();
    }
  } else return "";
}

export function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  };
}

export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getTimeAddedDate(value) {
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentSeconds = now.getSeconds();
  const fullDateTime = `${value}T${String(currentHours).padStart(
    2,
    "0"
  )}:${String(currentMinutes).padStart(2, "0")}:${String(
    currentSeconds
  ).padStart(2, "0")}`;
  return fullDateTime;
}

export function SuccessToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}

export function WarningToast(msg) {
  toast.warn(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}

export function ErrorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
}
