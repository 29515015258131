import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ErrorToast,
  getTimeAddedDate,
  SuccessToast,
  WarningToast,
} from "../../../../Util";
import { CreateMerchantRequests } from "../../../../Actions/RequestActions";

const MerchantRequest = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    date: "",
    narration: "",
    voucherId: "",
    amount: "",
  });

  const [error, setError] = useState(false);

  const [message, setMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      let value = e.target.value;
      value = value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/(\.\d{2})\d+/, "$1");
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseFloat(value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCreate = () => {
    setFormData({
      date: "",
      narration: "",
      voucherid: "",
      amount: "",
    });
    SuccessToast("Merchant request created successfully.");
    onClose("create");
  };

  const handleSubmit = async (e) => {
    const { date, narration, voucherId, amount } = formData;
    if (!date || !narration || !voucherId || !amount) {
      WarningToast("All fields are required.");
      return;
    } else
      CreateMerchantRequests(
        { ...formData, date: getTimeAddedDate(formData.date) },
        setError,
        handleCreate
      );
  };

  if (!isOpen) return null;

  return (
    <div className="modal d-block modal-blur-bg">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="px-5 modal-content">
          <button
            className="btn modal-label-txt modal-btn-bg btn-success position-absolute top-0 end-0 m-3"
            onClick={() => {
              onClose("close");
            }}
          >
            Close <FaTimes className="me-1" />
          </button>

          <div className="modal-header">
            <h2 className="modal-title mx-auto modal-heading-txt">
              Merchant Transfer Request
            </h2>
          </div>
          <div className="modal-body">
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <label className="modal-label-txt">Date *</label>
                <input
                  type="date"
                  name="date"
                  className="form-control modal-input-txt"
                  value={formData.date}
                  onChange={handleChange}
                  style={{
                    borderColor: validationErrors.date ? "red" : "",
                    backgroundColor: validationErrors.date ? "#fff8f8" : "",
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="modal-label-txt">Voucher ID *</label>
                <input
                  type="text"
                  name="voucherId"
                  className="form-control modal-input-txt w-80"
                  placeholder="000000"
                  value={formData.voucherId}
                  onChange={handleChange}
                  style={{
                    borderColor: validationErrors.voucherid ? "red" : "",
                    backgroundColor: validationErrors.voucherid
                      ? "#fff8f8"
                      : "",
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="modal-label-txt">Narration *</label>
              <textarea
                name="narration"
                className="form-control modal-input-txt w-50"
                rows="3"
                placeholder="comments"
                value={formData.narration}
                onChange={handleChange}
                style={{
                  borderColor: validationErrors.narration ? "red" : "",
                  backgroundColor: validationErrors.narration ? "#fff8f8" : "",
                }}
              />
            </div>

            <div className="mb-3">
              <label className="modal-label-txt">Amount *</label>
              <input
                type="number"
                name="amount"
                className="form-control modal-input-txt w-50"
                placeholder="20,500"
                value={formData.amount}
                onChange={handleChange}
                style={{
                  borderColor: validationErrors.amount ? "red" : "",
                  backgroundColor: validationErrors.amount ? "#fff8f8" : "",
                }}
              />
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <button
              className="btn w-50 modal-label-txt modal-btn-bg"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>

          {message && (
            <div
              className={`alert mt-3 ${
                message.type === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              {message.text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchantRequest;
