import axios from "axios";

const Axios = axios.create({
  baseURL: "https://ledger-backend.hoappayportal.com/api/v1",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default Axios;
